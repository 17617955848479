<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png" alt />
        <span class="center">客户管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">客户列表</span>
        <img src="../../assets/right.png" alt />
        <span class="center">客户详情</span>
      </div>
    </div>
    <el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>基础信息</span>
          </div>
        </div>
        <el-descriptions class="margin-top" style="margin-top:20px" :column="3">
          <el-descriptions-item label="企业名称">{{ detailInfo.name||'--'}}</el-descriptions-item>
          <el-descriptions-item label="企业统一信用编码">{{detailInfo.idCardNumber}}</el-descriptions-item>

          <el-descriptions-item label="联系人">{{detailInfo.username}}</el-descriptions-item>
          <el-descriptions-item label="联系方式">{{detailInfo.userPhone}}</el-descriptions-item>
        </el-descriptions>
      </el-card>
    </el-card>
    <el-card class="box-card">
      <div class="left_top">
        <div class="left_icon">
          <span></span>
          <span>客户交易量统计表</span>
        </div>
      </div>
      <el-table :data="tableData" style="width: 100%;margin:20px;">
        <el-table-column prop="name" label="企业">
          <template slot-scope="scope">
            <span class="clickColor" @click="goDetail(scope.row.code)">{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="idCardNumber" label="统一信用代码"></el-table-column>
        <el-table-column prop="totalMoney" label="累计发薪额/元" align="center"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
export default {
  data () {
    return {
      detailInfo: {
        name: '银企直联',
        idCardNumber: 'x135468794556',
        username: '张沾沾',
        userPhone: '15376972238',
      },
      code: '',
      tableData: [{
        name: '银企直联',
        idCardNumber: 'x1354687945561',
        totalMoney: '20000',
        code: 'sda321654'
      }, {
        name: '银企直联2',
        idCardNumber: 'x1354687945562',
        totalMoney: '20001',
        code: 'sda321654'
      }, {
        name: '银企直联3',
        idCardNumber: 'x1354687945563',
        totalMoney: '200000',
        code: 'sda321654'
      }, {
        name: '银企直联4',
        idCardNumber: 'x135468794556',
        totalMoney: '2000000',
        code: 'sda321654'
      }]
    }
  },
  created () {
    this.code = this.$route.query.code;
    console.log(this.detailInfo)
    // this.logoData()
  },
  methods: {
    logoData () {
      // const params = {
      //   code: this.code
      // };
      // customerInfo(params).then((res) => {
      //   this.detailInfo = res;
      //   console.log(this.detailInfo)

      // });
    },
    goDetail (code) {
      this.$router.push({
        path: '/system/customer/moneyDetail',
        query: { code },
      });
    }
  },
}
</script>